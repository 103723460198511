<template>
  <div id="project-list">
    <nuxt-link
      v-for="(project, index) in projects"
      :key="index"
      class="project"
      :to="`/projects/${project.id}`"
    >
      <h2 v-if="headingLevel === 2">
        {{ projectsContent[index].attributes.title }}
      </h2>
      <h3 v-if="headingLevel === 3">
        {{ projectsContent[index].attributes.title }}
      </h3>
      <p>{{ projectsContent[index].attributes.summary }}</p>
    </nuxt-link>
  </div>
</template>

<script>
import ToastMixin from "../mixins/toast";

export default {
  mixins: [ToastMixin],
  props: {
    projects: {
      type: Array,
      required: true
    },
    headingLevel: {
      type: Number,
      default: 2
    }
  },
  computed: {
    projectsContent() {
      if (this.$i18n.locale === "de") {
        return this.projects.map(project => project.german);
      }
      return this.projects.map(project => project.english);
    }
  }
};
</script>

<i18n>
{
  "en": {
    "projects-loading-error": "There was a problem loading the project list!"
  },
  "de": {
    "projects-loading-error": "Es gab ein Problem beim Laden der Projektliste!"
  }
}
</i18n>

<style>
.project {
  display: block;
  padding: 1rem;
  margin: 2.5rem 0;
  background-color: var(--dark-primary-color);
  border-left: 0.75rem solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  box-shadow: 2px 2px 7px 2px rgba(0, 0, 0, 0.4);
}
.project:hover {
  border-left: 0.75rem solid #f75959;
  box-shadow: 2px 2px 7px 4px rgba(0, 0, 0, 0.4);
  text-decoration: none;
}
.project * {
  color: white;
}
</style>
