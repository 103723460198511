<template>
  <div id="home">
    <div id="introduction-wrapper" class="h-card">
      <img
        id="headshot"
        class="u-photo"
        src="../assets/headshot.jpg"
        alt="A headshot of myself"
      >
      <div id="introduction">
        <p class="introduction-text">
          {{ $t("title-text-beginning") }}
        </p>
        <h1>
          <nuxt-link class="p-name u-url u-uid" to="/">
            Jarik Marwede
          </nuxt-link>
        </h1>
        <p class="introduction-text">
          {{ $t("title-text-ending") }}
        </p>
        <p class="introduction-text">
          {{ $t("introduction") }}
        </p>
        <p>{{ $t("postscript") }}</p>
      </div>
    </div>
    <div
      id="about-me"
      class="short-text"
    >
      <p>
        <i18n path="about-me-text">
          <nuxt-link to="/about">
            {{ $t("about-me") }}
          </nuxt-link>
        </i18n>
      </p>
    </div>
    <div id="projects">
      <h2>{{ $t("projects") }}</h2>
      <app-projectlist
        id="project-list"
        :projects="projects"
        :heading-level="3"
      />
      <nuxt-link
        to="/projects"
        class="link-button"
      >
        {{ $t("more-projects") }}
      </nuxt-link>
    </div>
    <div id="contact">
      <h2>{{ $t("contact") }}</h2>
      <div class="contact-links">
        <a
          class="link-button email-button"
          :href="'mailto:' + EMAIL_ADDRESS()"
        >
          <font-awesome-icon :icon="['far', 'envelope']" />
          Email
        </a>
        <nuxt-link
          class="link-button"
          to="/contact"
        >
          {{ $t("more-contact") }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { EMAIL_ADDRESS } from "assets/js/constants";
import projectList from "../components/projectList";
import { getProjects } from "../assets/js/api";

export default {
  components: {
    "app-projectlist": projectList
  },
  async asyncData() {
    let projects = await getProjects();
    projects = projects.slice(0, 3);
    return {
      projects
    };
  },
  data() {
    return {
      projects: []
    };
  },
  methods: {
    EMAIL_ADDRESS() {
      return EMAIL_ADDRESS;
    }
  }
};
</script>

<i18n>
{
  "en": {
    "title-text-beginning": "Hey, I'm",
    "title-text-ending": "and this is my website.",
    "introduction": "Over here you can find out who I am and what I do. You can also find links to various ways of contacting me.",
    "postscript": "P.S. If you want to view this site in german just click on the german flag in the Navigation Bar.",
    "about-me": "About Me",
    "about-me-text": "If you would like to know a bit about me you can go to the {0} page.",
    "projects": "Latest projects",
    "more-projects": "More projects",
    "contact": "Contact me",
    "more-contact": "More contact information"
  },
  "de": {
    "title-text-beginning": "Hey, mein Name ist",
    "title-text-ending": "und dies ist meine Webseite.",
    "introduction": "Hier kannst du mehr über mich und meine Projekte erfahren. Außerdem findest du Links zu zahlreichen Plattformen auf denen du mich kontaktieren kannst.",
    "postscript": "P.S. Wenn du die Seite lieber auf Englisch haben möchtest, klicke einfach auf die britische Flagge in der Navigationsleiste.",
    "about-me": "Über Mich",
    "about-me-text": "Wenn du ein bisschen über mich erfahren möchtest kannst du das auf der {0} Seite.",
    "projects": "Neuste Projekte",
    "more-projects": "Mehr Projekte",
    "contact": "Kontakt",
    "more-contact": "Mehr Kontaktinformationen"
  }
}
</i18n>

<style scoped>
  #home > * {
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
  }
  #introduction-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
  }
  #headshot {
    margin: 2rem;
    border-radius: 100%;
    width: 15rem;
    height: 15rem;
  }
  #introduction {
    text-align: center;
  }
  .introduction-text {
    font-size: 1.5rem;
  }
  .short-text {
    background-color: rgba(0, 0, 0, 0.02);
    padding: 1rem;
    font-size: 1.3rem;
    text-align: center;
  }
  #about-me a {
    color: var(--dark-primary-color);
  }
  #projects > h2 {
    text-align: center;
  }
  #contact {
    background-color: rgba(0, 0, 0, 0.03);
  }
  .contact-links {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }
  @media only screen and (min-width: 576px) {
    #headshot {
      width: 20rem;
      height: 20rem;
    }
  }
  @media only screen and (min-width: 768px) {
    #introduction-wrapper {
      flex-flow: row nowrap;
    }
    #projects > * {
      width: 50%;
    }
    .contact-links {
      flex-flow: row;
    }
  }
</style>
