import { icon } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default {
  methods: {
    showToast(type, message) {
      const errorToastOptions = {
        singleton: true,
        position: "bottom-center",
        action: {
          text: this.$t("close"),
          onClick(e, toastObject) { toastObject.goAway(0); }
        }
      };
      const successToastOptions = {
        position: "bottom-center",
        duration: 5000,
        action: {
          text: this.$t("close"),
          onClick(e, toastObject) { toastObject.goAway(0); }
        }
      };

      if (type === "success") {
        this.$toasted.success(message, successToastOptions);
      } else if (type === "error") {
        this.$toasted.error(icon(faExclamationTriangle).html + message, errorToastOptions);
      }
    }
  }
};
